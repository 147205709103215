<template>
  <div>
    <InvoiceBody ref="body" />
  </div>
</template>

<script>
import PageMixin from '../mixins/Page.js'
import InvoiceBody from '../bodies/Invoice.vue'

export default {
  mixins: [PageMixin],
  components: { InvoiceBody }
}
</script>
